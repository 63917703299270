import { DEFAULT_ACTION, LOG_OUT } from '../action-types';
import {
  HIDE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  SHOW_LOADING,
  UPDATE_USER,
  UPDATE_SELECTED_CANDIDATE,
  UPDATE_SELECTED_JOB_ID,
  CURRECY_OPTION
} from '../mutations';
import {
  AuthenticationError,
  NetworkError,
  ClientError,
  ServerError
} from '../../network/error-type';
import httpService from '../../network/http-service';
import { NOTIFICATION_MESSAGES } from '../constants';
import { removeCookie } from '../../utils/cookie-service';
import { API_ENDPOINT } from '../api-action';

export function showNotification() {
  return function (dispatch) {
    dispatch({ type: HIDE_NOTIFICATION });
  };
}

export function updateNotification(payload) {
  return function (dispatch) {
    dispatch({ type: UPDATE_NOTIFICATION, payload: payload });
  };
}

export function updateSpinner(payload) {
  return function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: payload });
  };
}

export function updateSelectedCandidate(payload) {
  return function (dispatch) {
    dispatch({ type: UPDATE_SELECTED_CANDIDATE, payload: payload });
  };
}

export function updateSelectedJobId(payload) {
  return function (dispatch) {
    dispatch({ type: UPDATE_SELECTED_JOB_ID, payload: payload });
  };
}

export function searchLocation(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.getFullLocation,
        query: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function searchSkills(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.getSkills, query: payload });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function employmentType() {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.employmentType });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function workVisa(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.workVisa, query: payload });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function masterData(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.masterData, query: payload });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}
export function currencyData() {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.getCurrencyOpt });
      dispatch({ type: CURRECY_OPTION, payload: response.data?.data });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function searchCompany(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.getCompany, query: payload });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function getCountry() {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.getCountry });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function getState(country_code) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.getState,
        query: { country_code }
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function getDomain() {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.getDomain });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function getCity(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.getCity, query: payload });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function getZipByCity(query) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.get({ action: API_ENDPOINT.zipByCity, query });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function getRoleData() {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.roleData });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function searchJobTitle(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.getJobTitle, query: payload });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function getEducation(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({ action: API_ENDPOINT.getEducation, query: payload });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}

export function getHomePage() {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({ action: API_ENDPOINT.homePage });
      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function contactUS(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({ action: API_ENDPOINT.contactUS, data: payload });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function handleError(action, error) {
  return function (dispatch) {
    action = action || DEFAULT_ACTION;

    if (error instanceof ClientError) {
      const errorObj = NOTIFICATION_MESSAGES[action].error;
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          title: errorObj.title,
          variant: errorObj.variant,
          message: error.message || errorObj.message
        }
      });
    } else if (error instanceof ServerError) {
      dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[action].error });
    } else if (error instanceof NetworkError) {
      dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[action].error });
    } else if (error instanceof AuthenticationError) {
      removeCookie('veriipro-emp-user');
      removeCookie('veriipro-emp-token');
      dispatch({ type: UPDATE_USER, payload: null });
      dispatch({ type: UPDATE_NOTIFICATION, payload: NOTIFICATION_MESSAGES[LOG_OUT] });
    } else {
      const errorObj = NOTIFICATION_MESSAGES[action].error;
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: {
          title: errorObj.title,
          variant: errorObj.variant,
          message: error.message || errorObj.message
        }
      });
    }
  };
}

export function searchLocationWithCountryState(payload) {
  return async function (dispatch) {
    try {
      const response = await httpService.get({
        action: API_ENDPOINT.searchLocationWithStateCountry,
        query: payload
      });
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      // dispatch(handleError(DEFAULT_ACTION, err));
    }
  };
}
export function getSearchHistory(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.get({
        action: API_ENDPOINT.searchHistory,
        query: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }
      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}
export function deleteSearchHistory(payload) {
  return async function (dispatch) {
    try {
      dispatch({ type: SHOW_LOADING, payload: true });
      const response = await httpService.delete({
        action: `${API_ENDPOINT.deleteSearchHistory}`,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response?.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function saveSearchHistory(payload) {
  return async function (dispatch) {
    dispatch({ type: SHOW_LOADING, payload: true });
    try {
      const response = await httpService.post({
        action: API_ENDPOINT.saveSearchHistory,
        data: payload
      });
      if (response?.data?.error) {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: { variant: 'error', message: response.data.msg }
        });
        return;
      }

      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { variant: 'success', message: response.data.msg }
      });

      return response.data;
    } catch (err) {
      if (err.statusCode === 409) throw err;
      dispatch(handleError(DEFAULT_ACTION, err));
    } finally {
      dispatch({ type: SHOW_LOADING, payload: false });
    }
  };
}

export function downloadS3File({fileUrl,fileName}) {
  return async function (dispatch) {
      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        dispatch(updateNotification({ variant: 'error', message: 'Downloading failed' }));
      }   
  }
}


