const routes = {
  home: '/',
  login: '/login/',
  employerLogin: `${process.env.REACT_APP_EMPLOYER_URL}/login/`,
  register: '/contact-us/',
  candidateHome: process.env.REACT_APP_WEB_URL,
  candidateLogin: `${process.env.REACT_APP_CANDIDATE_URL}/login/`,
  candidateRegister: `${process.env.REACT_APP_CANDIDATE_URL}/jobseeker-signup/`,
  contactUs: '/contact-us/',
  thankYou: '/thank-you/',
  termsOfService: `${process.env.REACT_APP_WEB_URL}/terms-of-use/`,
  privacyPolicy: `${process.env.REACT_APP_WEB_URL}/privacy-policy/`,
  about: `${process.env.REACT_APP_WEB_URL}/about-us/`,
  webJobs: `${process.env.REACT_APP_WEB_URL}/jobs/`,
  blog: `${process.env.REACT_APP_WEB_URL}/blog/`,
  jobs: '/jobs/',
  myJobs: 'my-jobs',
  teamJob: 'team-jobs',
  draftJob: 'draft-jobs',
  registerOtpVerify: '/register-otp-verify/',
  forgotPassword: '/forgot-password/',
  resetPassword: '/reset-password/',
  profile: '/profile',
  dashboard: '/dashboard/',
  taggedCandidate: '/tagged-candidate/',
  savedCandidate: '/saved-candidate/',
  downloadedCandidate: '/downloaded-candidate/',
  candidateSearch: '/search-candidate/',
  searchCandidateResult: '/search-candidate/result/',
  candidateProfile: '/view-candidate',
  postJob: '/post-job',
  viewJob: '/view-job',
  interview: '/interviews/',
  addInterview: '/interviews/add',
  editInterview: '/interviews/edit',
  applications: '/new-applications',
  shortListedCandidate: '/shortlisted-candidates',
  orderHistory: '/order-history/',
  allocationHistory: '/allocation-history/',
  premiumRequest: '/premium-request/',
  addPremiumRequest: '/premium-request/add',
  viewPremiumRequest: '/premium-request/view',
  manageSubUser: '/manage-user/',
  updateSubUser: '/manage-user/update',
  updateProfile: '/update-profile/',
  updateCompanyProfile: '/company-profile/',
  viewMessages: '/view-messages/',
  subscriptionHistory: '/subscription-history/',
  candidateReview: '/candidate-review',
  addCandidateReview: '/add-candidate-review',
  candidateSearchHistory: '/candidate-search-history/:type',
  candidateRecentSearchHistory: '/candidate-search-history/recent',
  candidateSaveSearchHistory: '/candidate-search-history/save',
  changePassword: '/change-password/',
  notFound: '/404',
  getIp: `${process.env.REACT_APP_API_URL}/v2/get_ip_details`,
  notApplicable: '-',
  commanFooter: {
    home: process.env.REACT_APP_WEB_URL,
    contact: `${process.env.REACT_APP_WEB_URL}/contact-us/`
  }
};

export default routes;
