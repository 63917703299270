import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Header from './components/shared/header';
import Notification from './components/shared/notification';
import { AppRoutes } from './navigation/app-routes';
import { updateUser } from './store/auth/actions';
import { getCookie, removeCookie, setCookie } from './utils/cookie-service';
import Loader from './components/shared/loader';
import routes from './constants/routes';
import { withRouter } from './utils/withRouter';
import Tags from './components/employer/tags';
import SendMessage from './components/employer/send-message';
import RestrictedAccess from './components/RestrictedAccess';
import CommanFooter from './components/shared/commanFooter';
import { updateSpinner } from './store/global/actions';
import { getIpCountry } from './utils/utility';
import { getHeaderData } from './store/employer/actions';

function App(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  const fetchUserCountry = async () => {
    updateSpinner(true);
    setIsLoading(true);
    const data = await getIpCountry();
    setHasAccess(data);
    updateSpinner(false);
    setIsLoading(false);
  };

  let deviceId = getCookie('deviceId');
  if (!deviceId) {
    deviceId = uuid().toString();
    setCookie('deviceId', deviceId);
  }

  const { navigate, location, auth, getHeaderData } = props;

  useEffect(() => {
    const notRedirectRoutes = [
      routes.login,
      routes.home,
      routes.forgotPassword,
      routes.thankYou,
      routes.notFound + '/'
    ];
    if (!notRedirectRoutes.includes(location.pathname) && auth?.isAuthenticated === null) {
      setCookie('redirectPath', location?.pathname);
    }
    if (auth.isAuthenticated === true) getHeaderData();
    if (auth.isAuthenticated === false) navigate(routes.login);
    else if (location.pathname.indexOf('login') > -1) navigate(routes.dashboard);
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (
      process.env.REACT_APP_ALLOWED_HOST !== window.location.hostname &&
      location.pathname !== routes.notFound
    ) {
      setTimeout(() => {
        navigate(routes.notFound);
      }, 50);
    } else if (location.pathname !== getCookie('prev-path')) {
      removeCookie('filters');
    }
    setCookie('prev-path', location.pathname);
    window.scrollTo({ behavior: 'instant', left: 0, top: 0 });
  }, [location.pathname]);

  const user = getCookie('veriipro-emp-user');
  if (user && !auth.currentUser) {
    const { updateUser } = props;
    updateUser(user);
  }

  /* For Country restriction */
  useEffect(() => {
    fetchUserCountry();
    window.history.scrollRestoration = 'manual';
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!hasAccess) {
    return (
      <div>
        <Header />
        <RestrictedAccess />
        <CommanFooter />
      </div>
    );
  }
  /* End code */

  return (
    <div>
      <SendMessage />
      <Tags />
      <Notification />
      <Loader />
      <Header />
      <div style={{ minHeight: '70vh' }}>
        <AppRoutes />
      </div>
      <CommanFooter />
    </div>
  );
}

export default connect((state) => ({ auth: state.auth }), { updateUser, getHeaderData })(
  withRouter(App)
);
