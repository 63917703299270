import moment from 'moment';
import { ZIP_REGEX, annualySalaryRange, hourlySalaryRange } from '../constants/constants';
import { maskCurrency } from './masking';
import routes from '../constants/routes';

export function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

export function getFromNowDateFormat(date) {
  const dateText = moment(date).fromNow();
  return dateText === 'a day ago' ? '1 day ago' : dateText;
}

export function formatDate(date, withTime) {
  return moment(date).format(`MMM DD, yyyy ${withTime ? 'hh:mm A' : ''}`);
}
export function shortFormatDate(date) {
  if (!date) return '';

  const dateArray = date.split('-');
  if (dateArray.length <= 1) return date;
  return moment(`${dateArray[0]}-${dateArray[0]}-${dateArray[1]}`).format('MMM yyyy');
}

export const copyToClip = (text) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  return true;
};

export const convertToSEO = (title) => {
  return title
    .trim()
    .replaceAll(' ', '-')
    .replaceAll('/', '-')
    .replaceAll(',', '')
    .toLocaleLowerCase();
};

export const isValidZipCode = (country, value) => {
  const regex = ZIP_REGEX[country];
  if (regex) {
    const pattern = new RegExp(regex);
    return pattern.test(value);
  }
  return false;
};

export const getYearList = (start) => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = start; i <= currentYear; i++) {
    years.push(i);
  }
  return years;
};

export const annualSalaryRangeOption = () => {
  const options = [];
  for (let i = annualySalaryRange.MIN_SALARY; i <= annualySalaryRange.MAX_SALARY; i += 10000) {
    options.push({ label: maskCurrency(i), value: i });
  }
  return options;
};
export const hourlySalaryRangeOption = () => {
  const options = [];
  for (let i = hourlySalaryRange.MIN_SALARY; i <= hourlySalaryRange.MAX_SALARY; i++) {
    options.push({ label: maskCurrency(i), value: i });
  }
  return options;
};

export const highLightWord = (_TEXT, keyword) => {
  if (keyword) {
    const searchTerms = Array.isArray(keyword)
      ? [...new Set(keyword)]
      : [...new Set(keyword.split(' '))];
    for (const text of searchTerms) {
      if (text.trim().length > 0) {
        const regex = new RegExp(`\\b${text.trim()}\\b`, 'gi');
        _TEXT = _TEXT?.replace(regex, (match) => `<mark>${match}</mark>`);
      }
    }
    return <span dangerouslySetInnerHTML={{ __html: _TEXT }} />;
  }
  return _TEXT;
};

export const removeExtraCharFromJobTitle = (str) => {
  if (!str || str.trim().length === 0) {
    return '';
  }
  return str.replace(/["()]/g, '').replace(/\s+(or|and|not)\s+/gi, ' ');
};

export const validateBracketAndCommas = (str) => {
  str = str.trim();
  const stack = [];

  if (str.length === 0) {
    return true;
  }

  for (const s of str) {
    if (s === '(' || s === ')' || s === '"') {
      if (s === ')') {
        if (stack.pop() !== '(') {
          return false;
        }
      } else if (s === '(') {
        stack.push(s);
      } else if (s === '"') {
        if (stack[stack.length - 1] === '"') {
          stack.pop();
        } else {
          stack.push(s);
        }
      }
    }
  }

  return stack.length === 0;
};

export const getIpCountry = async () => {
  try {
    const response = await fetch(routes.getIp);
    if (!response.ok) {
      return false;
    }
    let data;
    try {
      data = await response.json();
    } catch (jsonError) {
      return false;
    }
    return data?.data?.hasOwnProperty('hasAccess') ? data.data.hasAccess : false;
  } catch (e) {
    return false;
  }
};

export const getFileExtension = (url) => {
  const parts = url.split('.');
  return parts.length > 1 ? parts.pop() : '';
};

export const formatStartEndDate = (start, end, withBracket) => {
  if (!start && !end) {
    return '';
  }

  let str = withBracket ? '(' : '';

  if (start) {
    str += shortFormatDate(start);
  }

  if (start && end) {
    str += ' - ';
  }

  if (end) {
    str += shortFormatDate(end);
  }

  if (withBracket) {
    str += ')';
  }

  return str;
};

export const removeExtraSymbolFromSkill = (str) => {
  if (!str || str.trim().length === 0) {
    return '';
  }
  return str.trim().replace(/^•|•$/g, '').replace(/•/g, ' ');
};
